<template>
    <section>
        <vue-snotify></vue-snotify>
        <div class="row p-3 d-block">
            <b-container fluid>
                <div>
                    <h5>{{ $t('Following are spots in this cluster') }}: </h5>
                </div>
                <div>
                    <h5>{{ $t('total-spots') }}: {{ spotsData.length }}</h5>
                </div>
                <div class="full-width mt-3" v-for="spot in spotsData" :key="spot.id" >
                    <!-- <b-card :img-src="spot.properties.iconUrl" img-height="150" img-width="200" img-alt="Card image" img-left class="mb-3"> -->
                        <b-card>
                            <b-card-text>
                                <div class="row w-100">
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                    {{ $t('spot-id') }}
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6" >
                                        <a href="javascript:void(0)" @click="openDetils(spot.properties.id)"> {{ spot.properties.spot_id }} </a>
                                       
                                    </div>
                                </div>
                                <div class="row w-100">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        {{ $t('spot') }}
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        {{ spot.properties.title }}
                                    </div>
                                </div>
                                <div class="row w-100">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        {{ $t('spot-date') }}
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        {{ formatDateMM(spot.properties.spot_date) }}
                                    </div>
                                </div>
                                <div class="row w-100">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        {{ $t('spotted-by') }}
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        {{ spot.properties.spotted_username }}
                                    </div>
                                </div>
                                <!-- <div class="row w-100">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        Spotter Username:
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6"> 
                                        {{ spot.properties.spotted_username }}
                                    </div>
                                </div> -->
                            </b-card-text>
                    </b-card>
                </div>
            </b-container>
        </div>
    </section>
</template>

<script>
    import Vue from "vue";
    import
    API from "@/api";
    import Snotify, {
        SnotifyPosition
    } from "vue-snotify";
    import {
        validationMixin
    } from "vuelidate";
    import {
        required,
        minLength,
        email,
        requiredIf,
        sameAs
    } from "vuelidate/lib/validators";
    import moment from 'moment';
    
    const options = {
        toast: {
            position: SnotifyPosition.rightTop
        }
    };
    Vue.use(Snotify, options);
    
    export default {
        name: "openClusterSpotDetail",
        props: ["spotsData"],
        components: {},
        mixins: [validationMixin],
        data() {
            return {
                isDisabled: false,
                //  form: {
                //   subscriptions:{
                //     name: '',
                //     description: '',
                //   },
                //   principals: [],
                //   name: "",
                //   stream_desc: "",
                // },
            };
        },
        validations: {
            // form: {
            //   subscriptions: {
            //     name: {
            //       required
            //     },
            //     description: {
            //       required
            //     }
            //   },
            // }
        },
        methods: {
            openDetils(id) {
                console.log('open,' , id);
                this.$emit('openParentSpot', id, true);

            },
            formatDateMM(date) {
                // return moment(new Date(date)).format('MM-DD-YYYY')
                return moment(date, "MM-DD-YYYY HH:mm:ss A").format("MM-DD-YYYY")
            },
        },
        computed: {},
        async created() {},
        async beforeMount() {},
        async mounted() {
            console.log('spotsData in modal', this.spotsData);
        },
        beforeDestroy() {}
    };
</script>

<style scoped lang="scss">
    .full-width {
        width: 100% !important;
    }
    .full-width .row div:nth-child(2) {
        text-align: right;
    }
    .full-width .row div {
        padding: 0;
    }
    .full-width .row {
        margin: 0;
        flex-wrap: inherit;
    }
</style>